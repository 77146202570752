import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Fragment } from 'react';
import moment from 'moment';
import { POSITIONS } from '@nexxus/constants';

const BodyProjectsList = ({ items, type }) => {
  return (
    <>
      {items.map((pj, index) => (
        <Fragment key={index}>
          <Grid item xs={1}>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 'normal',
                color: 'black',
                textAlign: 'left',
              }}
            >
              {pj.Project.Client.companyName}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 'normal',
                color: 'black',
                textAlign: 'left',
              }}
            >
              {Object.values(POSITIONS).find((p) => p._id === pj.Project.position)?.label}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 'normal',
                color: 'black',
                textAlign: 'left',
              }}
            >
              {pj.Project.seniority}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 'normal',
                color: 'black',
                textAlign: 'left',
              }}
            >
              {pj.Project.mainTech}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 'normal',
                color: 'black',
                textAlign: 'left',
              }}
            >
              {'95%'}
            </Typography>
          </Grid>
          <Grid item xs={!type || type !== 'ARCHIVED' ? 2 : 1}>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 'normal',
                color: 'black',
                textAlign: 'left',
              }}
            >
              {pj.status.replaceAll('_', ' ')}
            </Typography>
          </Grid>
          {type && type === 'ARCHIVED' && (
            <Grid item xs={1}>
              <Typography
                textAlign="center"
                sx={{
                  fontSize: '12px',
                  fontWeight: 'normal',
                  color: 'black',
                }}
              >
                {moment(pj.updatedAt).format('MM/DD/YYYY')}
              </Typography>
            </Grid>
          )}
        </Fragment>
      ))}
    </>
  );
};

export default BodyProjectsList;
